import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/pages/usuarios/shared/usuario.model';
import { Unidade } from 'src/app/pages/unidades/shared/unidade.model';
import { UNID, USER } from 'src/app/app.api';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  usuario: Usuario;
  unidade: Unidade;

  constructor() { }

  ngOnInit() {
    this.usuario = JSON.parse(localStorage.getItem(USER));
    this.unidade = JSON.parse(localStorage.getItem(UNID));
  }
}
